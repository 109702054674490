var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden",
    },
    [
      _c(
        "v-card",
        { attrs: { width: "350" } },
        [
          _c(
            "v-card-title",
            { staticClass: "justify-center align-center nuvolos-header" },
            [
              _c("v-img", {
                staticClass: "py-5",
                attrs: {
                  "max-width": "200",
                  "max-height": "22",
                  src: require("@/assets/Nuvolos-transparent.svg"),
                },
              }),
            ],
            1
          ),
          !_vm.emailExists()
            ? [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-alert",
                      {
                        staticClass: "mb-0 mt-4",
                        attrs: { type: "error", text: "" },
                      },
                      [
                        _vm._v(
                          " You identity provider does not make your email address accessible for Nuvolos, which means you cannot use this login method to sign up to Nuvolos. Please use Email & Password authentication method instead. "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.toInvitationHome()
                          },
                        },
                      },
                      [_vm._v("Start again")]
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "h2",
                  { staticClass: "secondary--text text-center text-h6 mt-5" },
                  [
                    _vm._v(
                      "Preparing your " +
                        _vm._s(_vm.getUserName ? "account" : "workspace")
                    ),
                  ]
                ),
                _vm.getUserName
                  ? _c("user-name-form", {
                      on: { createAccount: _vm.signUpFlow },
                    })
                  : [
                      _c("v-card-text", [
                        _vm._v(" This might take a few seconds. "),
                        _c("br"),
                        _vm._v(" Please bear with us until we're done. "),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "text-center pb-16" },
                        [
                          _vm.error
                            ? _c("dashboard-error", {
                                attrs: { msg: _vm.errorMsg },
                              })
                            : [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.signupFlowProgressText)),
                                ]),
                                _c("v-progress-linear", {
                                  attrs: {
                                    stream: "",
                                    "buffer-value": "0",
                                    color: "primary",
                                    rounded: "",
                                    height: "6",
                                  },
                                  model: {
                                    value: _vm.progress,
                                    callback: function ($$v) {
                                      _vm.progress = $$v
                                    },
                                    expression: "progress",
                                  },
                                }),
                              ],
                          _vm.nuvolosError &&
                          _vm.error.response.data.code === "user_already_exists"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mt-4",
                                  attrs: { to: { name: "login" } },
                                },
                                [_vm._v(" go to login ")]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }